<template> </template>
<script>
import axios from "@/utils/request";
import apis from "@/api/const";

export default {
  data() {
    return {};
  },
  mounted() {
    this.authLogin();
  },
  methods: {
    getAuth(auth_token) {
      axios
        .post(apis.common.loginAdmin, {
          formData: true,
          auth_token: auth_token,
        })
        .then((res) => {
          if (res.code === "0200") {
            let auth_token = res.payload.auth_token;
            localStorage.setItem("saveToken", auth_token);
            this.$store.commit("setToken", auth_token);
            this.$router.push("/");
          } else {
            this.$message.error(res.msg);
            localStorage.setItem("saveToken", null);
            this.$store.commit("setToken", null);
            this.$router.push({ name: "login" });
          }
        });
    },
    authLogin() {
      try {
        const { auth_token } = this.$route.query;
        if (auth_token) {
          this.getAuth(auth_token);
        } else {
          this.$router.push({ name: "login" });
        }
      } catch (error) {}
    },
  },
};
</script>

<style lang="less" scoped></style>
